<div
  [class]="'assets-form-field assets-textarea ' + ('label-' + labelPosition)"
>
  <span class="assets-form-field-label">
    {{ label | translate }}
    <span
      *ngIf="hasRequiredField(control)"
      class="text-danger font-weight-light"
      >*</span
    >
  </span>
  <mat-form-field
    appearance="outline"
    floatLabel="always"
    [hideRequiredMarker]="true"
  >
    <textarea
      matInput
      [style.overflow]="hiddenScroll ? 'hidden' : 'auto'"
      [cdkTextareaAutosize]="autosize"
      [cdkAutosizeMaxRows]="autosizeMaxRows"
      [type]="type"
      [placeholder]="(placeholder | translate) + (placeholder ? '...' : '')"
      [formControl]="control"
      [maxLength]="maxLength"
    ></textarea>

    <span
      *ngIf="maxLength !== DEFAULT_MAX_LENGTH"
      class="d-flex justify-content-end"
      >{{ control.value?.length ?? 0 }}/{{ maxLength }}</span
    >

    <mat-error *ngIf="control?.invalid">{{ getErrorMessage() }}</mat-error>
    <!--    hint-->
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

    <mat-icon *ngIf="suffix" matSuffix>{{ suffix }}</mat-icon>

    <mat-icon *ngIf="prefix" matPrefix>{{ prefix }}</mat-icon>
  </mat-form-field>
</div>
