import {
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { hasRequiredField } from '@common/common/form.utils';
import { InputRefDirective } from '@common/directives/input-ref.directive';
import { InputMask } from '@common/models/form.models';
import { TranslateService } from '@ngx-translate/core';
import { IConfig } from 'ngx-mask';
import { Subject } from 'rxjs';
import { DEFAULT_MAX_LENGTH } from '@common/const/form.const';

export type FormLabelEnum = 'top' | 'left';

export type Validation = {
  key: string;
  label: string;
};

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const invalidCtrl = !!(
      control &&
      control.invalid &&
      control.parent?.dirty &&
      control.touched
    );
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );
    if (invalidCtrl || invalidParent) {
      control.setErrors({ notSame: true });
      return true;
    }
    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'assets-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() labelPosition: FormLabelEnum = 'top';
  @Input() label: string;
  @Input() name: string;
  @Input() id: string;
  @Input() autocomplete: string;
  @Input() hintText: string;

  @Input('control') set controlValue(value: UntypedFormControl) {
    this.control = value;
    this.toggleEnable(this._disabled ?? this.control.disabled);
  }

  control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() suffix?: string;
  @Input() prefix?: string;
  @Input() type = 'text';
  @Input() mask: InputMask;
  @Input() patterns: IConfig['patterns'];
  @Input() minValue = 0;
  @Input() maxValue: number;
  @Input() maxLength = DEFAULT_MAX_LENGTH;
  @Input() step: number;
  @Input() validationKeys?: Validation[];
  @Input() hint?: any;
  @Input() isPassword = false;
  @Input() isMatcher = false;
  @Input() readonly = false;
  @Input() readOnly: boolean;
  @Input() withClear = true;
  @Input() centeredText = false;
  @Input() set disabled(value: boolean) {
    this.toggleEnable(value);
    this._disabled = value;
  }

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(InputRefDirective) inputRefDirective: InputRefDirective;

  hasRequiredField = hasRequiredField;

  private _disabled: boolean;
  readonly destroy$ = new Subject<void>();

  translateService = inject(TranslateService);

  ngOnChanges(changes: SimpleChanges): void {
    this.inputRefDirective?.setInputProps();
  }

  toggleEnable(value: boolean) {
    if (value) {
      this.control.disable();
    } else {
      this.control.enable({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  matcher: MyErrorStateMatcher;

  ngOnInit(): void {
    if (this.isMatcher) {
      this.matcher = new MyErrorStateMatcher();
    }
  }

  clear() {
    this.input.nativeElement.focus();
    this.control.reset();
    this.control.markAllAsTouched();
    this.control.markAsDirty();
  }

  getErrorMessage() {
    if (this.control.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.control.hasError('requiredStartDate')) {
      return 'Enter project start date';
    }

    if (this.control.hasError('maxlength') && this.control?.errors) {
      return `required length ${this.control.errors.maxlength.requiredLength} actual length ${this.control.errors.maxlength.actualLength}`;
    }

    if (this.control.hasError('minlength') && this.control?.errors) {
      return `required length ${this.control.errors.minlength.requiredLength} actual length ${this.control.errors.minlength.actualLength}`;
    }

    if (this.control.hasError('max') && this.control?.errors) {
      return `required max value ${this.control.errors.max.max} actual max value ${this.control.errors.max.actual}`;
    }

    if (this.control.hasError('min') && this.control?.errors) {
      return `required min value ${this.control.errors.min.min} actual min value ${this.control.errors.min.actual}`;
    }

    if (this.control.hasError('nonPositive') && this.control?.errors) {
      return `You must enter a positive number`;
    }

    if (this.control.hasError('error')) {
      const key = this.control?.errors?.error;
      if (typeof key !== 'string') {
        return '';
      }

      return this.translateService.instant(this.control?.errors?.error);
    }

    if (this.validationKeys?.length) {
      let message;
      this.validationKeys.forEach(validationKey => {
        if (this.control.hasError(validationKey.key)) {
          message = validationKey.label;
        }
      });
      return message;
    }

    if (this.control.hasError('notSame') && this.control?.errors) {
      return `do not match`;
    }

    return this.control.hasError('email') ? 'Not a valid email' : '';
  }

  changeInputType(flag?: boolean) {
    flag ? (this.type = 'text') : (this.type = 'password');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
