import { Component, inject, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ActivityFilterMode,
  SortEnumType,
  UsersByFilterForOptionsQueryVariables,
} from '@common/generated/graphql';
import { Subject, takeUntil, tap } from 'rxjs';
import 'quill-mention';
import {
  MentionItem,
  MentionsService,
} from '@common/services/mentions.service';
import {
  MatLegacyTab,
  MatLegacyTabContent,
} from '@angular/material/legacy-tabs';

@Component({
  selector: 'assets-rich-text',
  templateUrl: 'rich-text.component.html',
  styles: [
    `
      ::ng-deep {
        .ql-editor p {
          white-space: pre-wrap;
        }
      }
    `,
  ],
})
export class RichTextComponent implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();
  @Input() control: FormControl;
  @Input() readOnly = false;
  private mentions: MentionItem[] = [];
  private mentionsService = inject(MentionsService);
  private readonly matTabContent = inject(MatLegacyTabContent, {
    optional: true,
  });
  private readonly matTab = inject(MatLegacyTab, { optional: true });

  constructor() {
    if (this.matTab && !this.matTabContent) {
      throw new Error(`If you use RichTextComponent inside MatTab, you have to make it lazy with MatTabContent.
        See: https://github.com/angular/angular/issues/17624`);
    }
  }

  quillConfig = {
    mention: {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ['@'],
      source: (
        searchTerm: string,
        renderList: (data: MentionItem[], searchText: string) => void,
        mentionChar: string,
      ) => {
        if (mentionChar === '@') {
          if (!this.mentions.length) {
            this.getUsers()
              .pipe(
                tap(response => {
                  this.mentions = response ?? [];
                  this.render(searchTerm, renderList, this.mentions);
                }),
                takeUntil(this.destroy$),
              )
              .subscribe();
          } else {
            this.render(searchTerm, renderList, this.mentions);
          }
        }
      },
    },
  };

  private render(
    searchTerm: string,
    renderList: (data: MentionItem[], searchText: string) => void,
    mentions: MentionItem[],
  ) {
    if (searchTerm.length === 0) {
      renderList(mentions, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < mentions.length; i++) {
        if (
          mentions[i].value.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          matches.push(mentions[i]);
        }
      }
      renderList(matches, searchTerm);
    }
  }

  private getUsers() {
    const params: UsersByFilterForOptionsQueryVariables = {
      request: {
        activityModes: [ActivityFilterMode.Active],
      },
      order: {
        firstName: SortEnumType.Asc,
      },
    };
    return this.mentionsService.getUsersForMention(params);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
