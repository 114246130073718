/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthService } from './services/auth.service';
import { CandidatesService } from './services/candidates.service';
import { CommissionFeeService } from './services/commission-fee.service';
import { CommonService } from './services/common.service';
import { ImpulseService } from './services/impulse.service';
import { InvoicesService } from './services/invoices.service';
import { PlacementsService } from './services/placements.service';
import { PositionsService } from './services/positions.service';
import { ReportingHoursService } from './services/reporting-hours.service';
import { ResourcesService } from './services/resources.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthService,
    CandidatesService,
    CommissionFeeService,
    CommonService,
    ImpulseService,
    InvoicesService,
    PlacementsService,
    PositionsService,
    ReportingHoursService,
    ResourcesService,
    UsersService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
