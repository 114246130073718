/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CandidateDto } from '../models/candidate-dto';
import { CandidateItemDtoResponseListDto } from '../models/candidate-item-dto-response-list-dto';
import { CandidatesRequest } from '../models/candidates-request';
import { HttpStatusCode } from '../models/http-status-code';
import { WithdrawalCandidateRequest } from '../models/withdrawal-candidate-request';

@Injectable({ providedIn: 'root' })
export class CandidatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `candidatesPost()` */
  static readonly CandidatesPostPath = '/candidates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidatesPost$Plain$Response(
    params?: {
      body?: CandidatesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CandidateItemDtoResponseListDto>> {
    const rb = new RequestBuilder(this.rootUrl, CandidatesService.CandidatesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CandidateItemDtoResponseListDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `candidatesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidatesPost$Plain(
    params?: {
      body?: CandidatesRequest
    },
    context?: HttpContext
  ): Observable<CandidateItemDtoResponseListDto> {
    return this.candidatesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CandidateItemDtoResponseListDto>): CandidateItemDtoResponseListDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidatesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidatesPost$Json$Response(
    params?: {
      body?: CandidatesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CandidateItemDtoResponseListDto>> {
    const rb = new RequestBuilder(this.rootUrl, CandidatesService.CandidatesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CandidateItemDtoResponseListDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `candidatesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidatesPost$Json(
    params?: {
      body?: CandidatesRequest
    },
    context?: HttpContext
  ): Observable<CandidateItemDtoResponseListDto> {
    return this.candidatesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CandidateItemDtoResponseListDto>): CandidateItemDtoResponseListDto => r.body)
    );
  }

  /** Path part for operation `candidateGet()` */
  static readonly CandidateGetPath = '/candidate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidateGet$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CandidateDto>> {
    const rb = new RequestBuilder(this.rootUrl, CandidatesService.CandidateGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CandidateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `candidateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidateGet$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CandidateDto> {
    return this.candidateGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CandidateDto>): CandidateDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidateGet$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CandidateDto>> {
    const rb = new RequestBuilder(this.rootUrl, CandidatesService.CandidateGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CandidateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `candidateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  candidateGet$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<CandidateDto> {
    return this.candidateGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CandidateDto>): CandidateDto => r.body)
    );
  }

  /** Path part for operation `candidateCandidateIdWithdrawalPost()` */
  static readonly CandidateCandidateIdWithdrawalPostPath = '/candidate/{candidateId}/withdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidateCandidateIdWithdrawalPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidateCandidateIdWithdrawalPost$Plain$Response(
    params: {
      candidateId: string;
      body?: WithdrawalCandidateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HttpStatusCode>> {
    const rb = new RequestBuilder(this.rootUrl, CandidatesService.CandidateCandidateIdWithdrawalPostPath, 'post');
    if (params) {
      rb.path('candidateId', params.candidateId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HttpStatusCode>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `candidateCandidateIdWithdrawalPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidateCandidateIdWithdrawalPost$Plain(
    params: {
      candidateId: string;
      body?: WithdrawalCandidateRequest
    },
    context?: HttpContext
  ): Observable<HttpStatusCode> {
    return this.candidateCandidateIdWithdrawalPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HttpStatusCode>): HttpStatusCode => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `candidateCandidateIdWithdrawalPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidateCandidateIdWithdrawalPost$Json$Response(
    params: {
      candidateId: string;
      body?: WithdrawalCandidateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HttpStatusCode>> {
    const rb = new RequestBuilder(this.rootUrl, CandidatesService.CandidateCandidateIdWithdrawalPostPath, 'post');
    if (params) {
      rb.path('candidateId', params.candidateId, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HttpStatusCode>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `candidateCandidateIdWithdrawalPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  candidateCandidateIdWithdrawalPost$Json(
    params: {
      candidateId: string;
      body?: WithdrawalCandidateRequest
    },
    context?: HttpContext
  ): Observable<HttpStatusCode> {
    return this.candidateCandidateIdWithdrawalPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HttpStatusCode>): HttpStatusCode => r.body)
    );
  }

}
