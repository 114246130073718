/* tslint:disable */
/* eslint-disable */
export enum DictionariesEnum {
  JOBTITLE = 'JOBTITLE',
  SENIORITY = 'SENIORITY',
  ENGLISHLEVEL = 'ENGLISHLEVEL',
  COUNTRY = 'COUNTRY',
  TIMEZONE = 'TIMEZONE',
  SKILL = 'SKILL',
  LEVEL = 'LEVEL',
  WITHDRAWAL = 'WITHDRAWAL'
}
