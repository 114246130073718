import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormFileGroup } from '@common/components/form/form-file-input/form-file-group';
import { TranslateService } from '@ngx-translate/core';

export type UploadFileBase64 = {
  documentExtension: string;
  name: string;
  content: string;
};

const requiredValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const formFileGroup = control as FormFileGroup;

  // The id needs to be checked because in this case the content field may be empty,
  // but the file input field may be required at the same time, so the id value will ensure that the file exists and is not empty.
  if (
    formFileGroup.controls.id.value ||
    (formFileGroup.controls.name.value && formFileGroup.controls.content.value)
  ) {
    return null;
  } else {
    return {
      required: true,
    };
  }
};

@Component({
  selector: 'assets-form-file-input',
  templateUrl: './form-file-input.component.html',
  styleUrls: ['./form-file-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFileInputComponent implements OnInit, OnDestroy {
  protected readonly innerControl = new FormControl();
  protected required = false;
  protected control: FormFileGroup;
  @Input('control') set controlValue(value: FormFileGroup) {
    this.control = value;
    this.checkValidity();
  }

  @Input() label: string;
  @Input() accept: string = '';
  @Input() clearHidden: boolean;
  private readonly destroy$ = new Subject<void>();
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly translateService = inject(TranslateService);

  ngOnInit(): void {
    this.checkValidity();
    this.control.setErrors(this.innerControl.errors ?? null);
    this.innerControl.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(status =>
        this.control.setErrors(
          status === 'INVALID' ? this.innerControl.errors : null,
        ),
      );
  }

  onFileChange(file: UploadFileBase64 | null) {
    if (file) {
      this.control.patchValue({
        ...file,
        fileExtension: file.documentExtension,
      });
    } else {
      this.control.setValue(this.control.defaultValue);
    }

    this.checkValidity();

    this.control.markAsDirty();
  }

  checkValidity() {
    //TODO simplify validation and connection between inner and outer controls #96073
    if (this.control.hasValidator(Validators.required)) {
      this.control.addValidators(requiredValidator);

      if (!this.control.controls.id.value) {
        this.innerControl.addValidators(Validators.required);
      }

      this.required = true;
    } else {
      this.control.removeValidators(requiredValidator);

      this.innerControl.removeValidators(Validators.required);
      this.required = false;
    }

    this.cdRef.detectChanges();
    this.innerControl.updateValueAndValidity();
    this.control.updateValueAndValidity();
  }

  getErrorMessage() {
    if (this.control.errors?.emptyFile) {
      return this.translateService.instant(this.control.errors?.emptyFile);
    }

    if (this.control.hasError('invalidFileType') && this.control?.errors) {
      return 'Not a valid file format';
    }

    if (this.control.hasError('error')) {
      const key = this.control?.errors?.error;
      if (typeof key !== 'string') {
        return '';
      }

      return this.translateService.instant(this.control?.errors?.error);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
