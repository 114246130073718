/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Auth } from '../models/auth';
import { ExternalAuthRequest } from '../models/external-auth-request';
import { GetByEmailRequest } from '../models/get-by-email-request';
import { RefreshTokenRequest } from '../models/refresh-token-request';
import { SignInResponse } from '../models/sign-in-response';
import { Token } from '../models/token';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accessPost()` */
  static readonly AccessPostPath = '/access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accessPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accessPost$Plain$Response(
    params?: {
      body?: Auth
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SignInResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AccessPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accessPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accessPost$Plain(
    params?: {
      body?: Auth
    },
    context?: HttpContext
  ): Observable<SignInResponse> {
    return this.accessPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignInResponse>): SignInResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accessPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accessPost$Json$Response(
    params?: {
      body?: Auth
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SignInResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AccessPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accessPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accessPost$Json(
    params?: {
      body?: Auth
    },
    context?: HttpContext
  ): Observable<SignInResponse> {
    return this.accessPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignInResponse>): SignInResponse => r.body)
    );
  }

  /** Path part for operation `refreshPost()` */
  static readonly RefreshPostPath = '/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  refreshPost$Plain$Response(
    params?: {
      body?: RefreshTokenRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Token>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.RefreshPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Token>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  refreshPost$Plain(
    params?: {
      body?: RefreshTokenRequest
    },
    context?: HttpContext
  ): Observable<Token> {
    return this.refreshPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Token>): Token => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  refreshPost$Json$Response(
    params?: {
      body?: RefreshTokenRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Token>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.RefreshPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Token>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  refreshPost$Json(
    params?: {
      body?: RefreshTokenRequest
    },
    context?: HttpContext
  ): Observable<Token> {
    return this.refreshPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Token>): Token => r.body)
    );
  }

  /** Path part for operation `externalSignInPost()` */
  static readonly ExternalSignInPostPath = '/external-sign-in';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalSignInPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  externalSignInPost$Plain$Response(
    params?: {
      body?: ExternalAuthRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SignInResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ExternalSignInPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalSignInPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  externalSignInPost$Plain(
    params?: {
      body?: ExternalAuthRequest
    },
    context?: HttpContext
  ): Observable<SignInResponse> {
    return this.externalSignInPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignInResponse>): SignInResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalSignInPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  externalSignInPost$Json$Response(
    params?: {
      body?: ExternalAuthRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SignInResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ExternalSignInPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignInResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalSignInPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  externalSignInPost$Json(
    params?: {
      body?: ExternalAuthRequest
    },
    context?: HttpContext
  ): Observable<SignInResponse> {
    return this.externalSignInPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignInResponse>): SignInResponse => r.body)
    );
  }

  /** Path part for operation `resendTwoStepCodePost()` */
  static readonly ResendTwoStepCodePostPath = '/resend-two-step-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendTwoStepCodePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resendTwoStepCodePost$Response(
    params?: {
      body?: GetByEmailRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.ResendTwoStepCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resendTwoStepCodePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resendTwoStepCodePost(
    params?: {
      body?: GetByEmailRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.resendTwoStepCodePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
