/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BaseFileDto } from '../models/base-file-dto';
import { ChangeDateRequest } from '../models/change-date-request';
import { ChangeResourceStatusRequest } from '../models/change-resource-status-request';
import { EmployeeChangeStateWarningDtoWarningDto } from '../models/employee-change-state-warning-dto-warning-dto';
import { ResourceDto } from '../models/resource-dto';
import { ResourceItemDtoResponseListDto } from '../models/resource-item-dto-response-list-dto';
import { ResourcesRequest } from '../models/resources-request';
import { ShortResourceDto } from '../models/short-resource-dto';
import { UpdateResourceRequest } from '../models/update-resource-request';
import { VendorRateDto } from '../models/vendor-rate-dto';
import { VendorRateForCostRequest } from '../models/vendor-rate-for-cost-request';

@Injectable({ providedIn: 'root' })
export class ResourcesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `resourcesPost()` */
  static readonly ResourcesPostPath = '/resources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourcesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resourcesPost$Plain$Response(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResourceItemDtoResponseListDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ResourcesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceItemDtoResponseListDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourcesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resourcesPost$Plain(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<ResourceItemDtoResponseListDto> {
    return this.resourcesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceItemDtoResponseListDto>): ResourceItemDtoResponseListDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourcesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resourcesPost$Json$Response(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResourceItemDtoResponseListDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ResourcesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceItemDtoResponseListDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourcesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resourcesPost$Json(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<ResourceItemDtoResponseListDto> {
    return this.resourcesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceItemDtoResponseListDto>): ResourceItemDtoResponseListDto => r.body)
    );
  }

  /** Path part for operation `resourceGet()` */
  static readonly ResourceGetPath = '/resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceGet$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResourceDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ResourceGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceGet$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<ResourceDto> {
    return this.resourceGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceDto>): ResourceDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceGet$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResourceDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ResourceGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceGet$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<ResourceDto> {
    return this.resourceGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceDto>): ResourceDto => r.body)
    );
  }

  /** Path part for operation `resourceCvGet()` */
  static readonly ResourceCvGetPath = '/resource-cv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceCvGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceCvGet$Plain$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ResourceCvGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourceCvGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceCvGet$Plain(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.resourceCvGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceCvGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceCvGet$Json$Response(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BaseFileDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ResourceCvGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseFileDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourceCvGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceCvGet$Json(
    params?: {
      id?: number;
    },
    context?: HttpContext
  ): Observable<BaseFileDto> {
    return this.resourceCvGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseFileDto>): BaseFileDto => r.body)
    );
  }

  /** Path part for operation `shortResourcesPost()` */
  static readonly ShortResourcesPostPath = '/short-resources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shortResourcesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  shortResourcesPost$Plain$Response(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ShortResourceDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ShortResourcesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShortResourceDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shortResourcesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  shortResourcesPost$Plain(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<Array<ShortResourceDto>> {
    return this.shortResourcesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShortResourceDto>>): Array<ShortResourceDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shortResourcesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  shortResourcesPost$Json$Response(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ShortResourceDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ShortResourcesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShortResourceDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shortResourcesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  shortResourcesPost$Json(
    params?: {
      body?: ResourcesRequest
    },
    context?: HttpContext
  ): Observable<Array<ShortResourceDto>> {
    return this.shortResourcesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShortResourceDto>>): Array<ShortResourceDto> => r.body)
    );
  }

  /** Path part for operation `addResourcePost()` */
  static readonly AddResourcePostPath = '/add-resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addResourcePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addResourcePost$Plain$Response(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.AddResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addResourcePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addResourcePost$Plain(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.addResourcePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addResourcePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addResourcePost$Json$Response(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.AddResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addResourcePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addResourcePost$Json(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.addResourcePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `updateResourcePost()` */
  static readonly UpdateResourcePostPath = '/update-resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateResourcePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateResourcePost$Plain$Response(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.UpdateResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateResourcePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateResourcePost$Plain(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.updateResourcePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateResourcePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateResourcePost$Json$Response(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.UpdateResourcePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateResourcePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateResourcePost$Json(
    params?: {
      body?: UpdateResourceRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.updateResourcePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `changeResourceStatusPost()` */
  static readonly ChangeResourceStatusPostPath = '/change-resource-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeResourceStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceStatusPost$Plain$Response(
    params?: {
      body?: ChangeResourceStatusRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmployeeChangeStateWarningDtoWarningDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ChangeResourceStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeChangeStateWarningDtoWarningDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeResourceStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceStatusPost$Plain(
    params?: {
      body?: ChangeResourceStatusRequest
    },
    context?: HttpContext
  ): Observable<EmployeeChangeStateWarningDtoWarningDto> {
    return this.changeResourceStatusPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeChangeStateWarningDtoWarningDto>): EmployeeChangeStateWarningDtoWarningDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeResourceStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceStatusPost$Json$Response(
    params?: {
      body?: ChangeResourceStatusRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmployeeChangeStateWarningDtoWarningDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ChangeResourceStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeChangeStateWarningDtoWarningDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeResourceStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceStatusPost$Json(
    params?: {
      body?: ChangeResourceStatusRequest
    },
    context?: HttpContext
  ): Observable<EmployeeChangeStateWarningDtoWarningDto> {
    return this.changeResourceStatusPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeChangeStateWarningDtoWarningDto>): EmployeeChangeStateWarningDtoWarningDto => r.body)
    );
  }

  /** Path part for operation `changeResourceInactiveDatePost()` */
  static readonly ChangeResourceInactiveDatePostPath = '/change-resource-inactive-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeResourceInactiveDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceInactiveDatePost$Plain$Response(
    params?: {
      body?: ChangeDateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ChangeResourceInactiveDatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeResourceInactiveDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceInactiveDatePost$Plain(
    params?: {
      body?: ChangeDateRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.changeResourceInactiveDatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeResourceInactiveDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceInactiveDatePost$Json$Response(
    params?: {
      body?: ChangeDateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.ChangeResourceInactiveDatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeResourceInactiveDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeResourceInactiveDatePost$Json(
    params?: {
      body?: ChangeDateRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.changeResourceInactiveDatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `vendorRateForCostPost()` */
  static readonly VendorRateForCostPostPath = '/vendor-rate-for-cost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vendorRateForCostPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vendorRateForCostPost$Plain$Response(
    params?: {
      body?: VendorRateForCostRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorRateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.VendorRateForCostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorRateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vendorRateForCostPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vendorRateForCostPost$Plain(
    params?: {
      body?: VendorRateForCostRequest
    },
    context?: HttpContext
  ): Observable<VendorRateDto> {
    return this.vendorRateForCostPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorRateDto>): VendorRateDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vendorRateForCostPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vendorRateForCostPost$Json$Response(
    params?: {
      body?: VendorRateForCostRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorRateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ResourcesService.VendorRateForCostPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorRateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vendorRateForCostPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  vendorRateForCostPost$Json(
    params?: {
      body?: VendorRateForCostRequest
    },
    context?: HttpContext
  ): Observable<VendorRateDto> {
    return this.vendorRateForCostPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorRateDto>): VendorRateDto => r.body)
    );
  }

}
