import { KeyValuePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { GroupedUserPreferences } from '@common/components/settings/pages/user-settings/user-settings.component';
import {
  CommonService,
  UserPreferenceEnum,
  UsersService,
} from '@common/generated/gateway-api';
import { UserInfo } from '@common/generated/graphql';
import UserService from '@common/services/user.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends UserService<UserPreferenceEnum> {
  readonly usersService = inject(UsersService);
  readonly commonService = inject(CommonService);
  readonly keyValuePipe = inject(KeyValuePipe);

  reportIssueVp(message: string) {
    return this.commonService.reportIssuePost({
      body: {
        message,
      },
    });
  }

  override resetPassword(password: string) {
    return this.usersService.resetPasswordPost$Json({
      body: { password: password },
    });
  }

  override getGroupedUserPreferences() {
    return this.usersService.groupedUserPreferencesGet$Json().pipe(
      map(x => {
        return <GroupedUserPreferences>{
          ...x,
          eventNotifications: this.keyValuePipe.transform(x.eventNotifications),
        };
      }),
    );
  }

  override updateUserPreference(
    isActive: boolean,
    preference: UserPreferenceEnum,
    id?: string,
  ) {
    return this.usersService
      .updateUserPreferencePost$Json({
        body: { preference: preference, isActive: isActive },
      })
      .pipe(
        map(x => {
          return <GroupedUserPreferences>{
            ...x,
            eventNotifications: this.keyValuePipe.transform(
              x.eventNotifications,
            ),
          };
        }),
      );
  }

  override toggleNotifications(
    isActive: boolean,
  ): Observable<GroupedUserPreferences> {
    return this.usersService
      .toggleNotificationsPost$Json({ body: { isActive: isActive } })
      .pipe(
        map(x => {
          return <GroupedUserPreferences>{
            ...x,
            eventNotifications: this.keyValuePipe.transform(
              x.eventNotifications,
            ),
          };
        }),
      );
  }

  override updateUser(user: UserInfo) {
    return this.usersService.updateUserPost$Json({
      body: { firstName: user.firstName, lastName: user.lastName },
    });
  }
}
