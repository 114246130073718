import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { quillModules } from '@common/const/text-editor-config.const';
import { QuillModule, QuillConfigModule } from 'ngx-quill';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { DEFAULT_SPINNER_CONFIG } from '@common/const/apollo.const';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18N_CONFIG } from '@common/const';
import { I18nModule } from '@common/components/i18n';
import { WrapperModule } from '@common/components/wrapper/wrapper.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule, MaterialModule } from '@common/modules';
import { SharedModule } from '@common/shared.module';
import { FormsModule } from '@angular/forms';
import {
  BrowserModule,
  REMOVE_STYLES_ON_COMPONENT_DESTROY,
  bootstrapApplication,
} from '@angular/platform-browser';
import { CustomTitleStrategy } from '@common/services/title-strategy.service';
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { MAT_LEGACY_MENU_DEFAULT_OPTIONS as MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/legacy-menu';
import { config } from '@common/const/dialog.const';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { AuthInterceptor } from '@common/interceptors/auth.interceptor';
import { AuthorizeErrorWithRelogin } from '@common/interceptors/authorize-error-with-relogin.interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { MatPaginatorI18nService } from '@common/services/mat-paginator-i18n.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { routes } from '@app/shared/const';
import { environment } from '@root/environments/environment';
import {
  ENVIRONMENT,
  BASE_URL,
  TITLE_PREFIX,
  NAVIGATION_ROUTES,
  NON_INTERCEPTED_REQUEST_CHECKER,
} from '@common/const/common';
import { MentionsVpService } from './app/shared/services/mentions.service';
import { MentionsService } from '@common/services/mentions.service';
import { ConfigVPService } from './app/core/services/config.service';
import { ConfigService } from '@common/services/core/config.service';
import { AuthenticationVPService } from './app/core/services/authentication.service';
import { AuthenticationService } from '@common/services/authentication.service';
import { SpinnerService } from '@common/components/spinner/spinner.service';
import { appRoutes } from './app/app.routes';
import { ApiModule } from '@common/generated/gateway-api';
import { RefreshTokenService } from '@common/services/refresh-token.service';
import { RefreshTokenInterceptor } from '@common/interceptors/refresh-token.interceptor';
import { RefreshTokenVPService } from '@app/core/services/refresh-token.service';
import nonInterceptedReqChecker from '@app/core/nonInterceptedReqChecker';
import ReportIssueService from '@common/services/report-issue.service';
import ReportIssueVpService from '@app/core/services/report-issue-vp.service';
import { KeyValuePipe } from '@angular/common';
import CredentialsInterceptor from '@app/core/interceptors/credentials.interceptor';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      SharedModule,
      CoreModule,
      MaterialModule,
      WrapperModule,
      I18nModule.forRoot(I18N_CONFIG),
      NgxSpinnerModule.forRoot({ ...DEFAULT_SPINNER_CONFIG }),
      PowerBIEmbedModule,
      QuillModule.forRoot(),
      QuillConfigModule.forRoot({
        modules: {
          syntax: false,
          toolbar: quillModules.toolbar,
        },
        bounds: 'quill-editor',
      }),
      ApiModule.forRoot({ rootUrl: environment.apiURL }),
    ),
    SpinnerService,
    {
      provide: RefreshTokenService,
      useClass: RefreshTokenVPService,
    },
    {
      provide: AuthenticationService,
      useClass: AuthenticationVPService,
    },
    {
      provide: ConfigService,
      useClass: ConfigVPService,
    },
    {
      provide: MentionsService,
      useClass: MentionsVpService,
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: BASE_URL,
      useValue: environment.apiURL,
    },
    {
      provide: TITLE_PREFIX,
      useValue: 'PP',
    },
    {
      provide: NAVIGATION_ROUTES,
      useValue: routes,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    {
      provide: ReportIssueService,
      useClass: ReportIssueVpService,
    },
    KeyValuePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '854389122288-crdtnvgj41tcdimpqsma0gglqme5i5rg.apps.googleusercontent.com',
              { oneTapEnabled: false },
            ),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: NON_INTERCEPTED_REQUEST_CHECKER,
      useValue: nonInterceptedReqChecker,
    },
    // AppInitService,
    // { provide: APP_INITIALIZER,useFactory: initializeApp, deps: [AppInitService], multi: true}
    /* TODO TASK 84293
         Token refresh is currently implemented in Apollo Http Link.
         The reason is that in the http interceptor, when refreshing a token,
         only the request that initializes the request for new tokens propagates the response to the view.
         The rest of the requests succeed, but their data is not passed to the view.
         After all, the user interface is inconsistent.*/
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeErrorWithRelogin,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { ...config } },
    { provide: MAT_MENU_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: true },
  ],
}).catch(err => console.error(err));
