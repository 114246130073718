import { Injectable, inject } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEventType,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError, of, EMPTY } from 'rxjs';
import {
  switchMap,
  catchError,
  filter,
  finalize,
  skip,
  takeUntil,
  tap,
  delay,
} from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { SystemErrorCode } from '../enums/system-error-code.enum';
import { NON_INTERCEPTED_REQUEST_CHECKER } from '@common/const/common';

/* TODO TASK 84293
     Token refresh is currently implemented in Apollo Http Link.
     The reason is that in the http interceptor, when refreshing a token,
     only the request that initializes the request for new tokens propagates the response to the view.
     The rest of the requests succeed, but their data is not passed to the view.
     After all, the user interface is inconsistent.

     TODO remove all //console.logs
     */

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private authService = inject(AuthenticationService);
  private nonInterceptedReqChecker = inject(NON_INTERCEPTED_REQUEST_CHECKER);
  private httpClient = inject(HttpClient);
  private refreshTokenInProgress = false;

  private isAuthErrorResponse(event: HttpEvent<any>): boolean {
    const isUnauthorized =
      event instanceof HttpErrorResponse &&
      event.status === HttpStatusCode.Unauthorized;
    return isUnauthorized;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.nonInterceptedReqChecker(req)) {
      return next.handle(req);
    }

    req = req.clone();
    //console.log('intercept', req.body?.operationName);
    return next.handle(req).pipe(
      catchError(event => {
        if (!this.isAuthErrorResponse(event)) {
          //console.log('other error', req.body?.operationName);
          return throwError(() => event);
        } else {
          if (this.refreshTokenInProgress) {
            //console.log('refreshTokenInProgress', req.body?.operationName);
            return this.authService.accessToken$.pipe(
              skip(1),
              distinctUntilChanged(),
              switchMap(token => {
                //console.log('accessToken', req.body?.operationName, token);
                return token
                  ? this.httpClient.request(req)
                  : // .pipe(tap(v=> console.log(v),))
                    of(event);
              }),
              takeUntil(
                this.authService.user$.pipe(filter(user => user === null)),
              ),
            );
          } else {
            this.refreshTokenInProgress = true;
            //console.log('refreshTokenInProgress false', req.body?.operationName);
            return this.authService.refreshToken().pipe(
              finalize(() => (this.refreshTokenInProgress = false)),
              switchMap(result => {
                //console.log('refreshToken', req.body?.operationName, result);
                return result === false
                  ? of(event)
                  : this.httpClient.request(req);
                //.pipe(tap(v=> console.log(v)  ));
              }),
            );
          }
        }
      }),
    );
  }
}
