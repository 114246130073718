/* tslint:disable */
/* eslint-disable */
export enum CandidateStateEnum {
  NEW_CANDIDATE = 'NEW_CANDIDATE',
  EVALUATION = 'EVALUATION',
  DELIVERY_REVIEW = 'DELIVERY_REVIEW',
  REJECTED = 'REJECTED',
  BENCH_APPROVAL = 'BENCH_APPROVAL',
  CLIENT_PRESENTATION = 'CLIENT_PRESENTATION',
  ONBOARDING = 'ONBOARDING',
  PLACED = 'PLACED',
  WITHDRAWN = 'WITHDRAWN',
  CLOSED = 'CLOSED',
  DELIVERY_REJECTED = 'DELIVERY_REJECTED',
  ONBOARDING_QUEUE = 'ONBOARDING_QUEUE',
  CLIENT_REJECTED = 'CLIENT_REJECTED',
  CLIENT_INTERVIEW = 'CLIENT_INTERVIEW',
  WAITING_FOR_CLIENT_FEEDBACK = 'WAITING_FOR_CLIENT_FEEDBACK'
}
