import { Directive, inject } from '@angular/core';
import { StatusChipResolverDirective } from '@common/directives/status-chip-resolver/status-chip-resolver.directive';
import { InvoiceStatusEnum } from '@common/generated/graphql';
import { TranslateService } from '@ngx-translate/core';

type Item = InvoiceStatusEnum | undefined;
@Directive({
  selector: '[assetsInvoiceStatusChipResolver]',
})
export default class InvoiceStatusChipResolverDirective extends StatusChipResolverDirective<Item> {
  protected readonly translateService = inject(TranslateService);

  override getValues(model: Item) {
    let status = model?.toString();
    let classNames: string[];
    switch (model) {
      case InvoiceStatusEnum.Review:
        classNames = ['assets-chip-gray'];
        break;
      case InvoiceStatusEnum.NoBilling:
      case InvoiceStatusEnum.PastDue:
      case InvoiceStatusEnum.Cancelled:
        classNames = ['assets-chip-pink', 'assets-chip-outline-bold'];
        break;
      case InvoiceStatusEnum.PaApproved:
      case InvoiceStatusEnum.OnHold:
        classNames = ['assets-chip-yellow'];
        break;
      case InvoiceStatusEnum.ExecutiveApproved:
        classNames = ['assets-chip-primary'];
        break;
      case InvoiceStatusEnum.Paid:
        classNames = ['assets-chip-success'];
        break;
      case InvoiceStatusEnum.Billed:
        classNames = ['assets-chip-success', 'assets-chip-outline-bold'];
        break;
      default:
        classNames = ['assets-chip-danger'];
        break;
    }

    if (
      [InvoiceStatusEnum.Billed, InvoiceStatusEnum.NoBilling].includes(model!)
    ) {
      status = `${this.translateService.instant(status ?? '')}!`;
    }

    return {
      classNames,
      contentToken: status ?? '',
    };
  }
}
