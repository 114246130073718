import { HttpRequest } from '@angular/common/http';

const NON_INTERCEPTED_URLS = [
  '/access',
  '/refresh',
  '/forgot-password',
  '/resend-two-step-code',
] as const;

export default function nonInterceptedReqChecker(
  req: HttpRequest<any>,
): boolean {
  const isFullUrl = !req.url.startsWith('/');
  const isNonInterceptedRequest =
    !isFullUrl ||
    NON_INTERCEPTED_URLS.includes(
      new URL(req.url).pathname as (typeof NON_INTERCEPTED_URLS)[number],
    );

  return isNonInterceptedRequest;
}
