import { Injectable, inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { ConfigService } from '@common/services/core/config.service';
import { CommonService } from '@common/generated/gateway-api';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigVPService extends ConfigService {
  readonly commonService = inject(CommonService);
  readonly apiService = inject(ApiService);

  override getBackendVersion() {
    return this.apiService
      .withLoader(this.commonService.versionGet$Json())
      .pipe(
        map(({ releaseVersion }) => releaseVersion ?? null),
        catchError(() => of(null)),
      );
  }
}
