/* tslint:disable */
/* eslint-disable */
export enum PartnerBillStatusEnum {
  REVIEW = 'REVIEW',
  NO_PAYMENT = 'NO_PAYMENT',
  ON_HOLD = 'ON_HOLD',
  APPROVED = 'APPROVED',
  EXECUTIVE_APPROVED = 'EXECUTIVE_APPROVED',
  PAID = 'PAID'
}
