/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ApplyPositionRequest } from '../models/apply-position-request';
import { BaseDictionaryDto } from '../models/base-dictionary-dto';
import { PositionDto } from '../models/position-dto';
import { PositionItemDtoResponseListDto } from '../models/position-item-dto-response-list-dto';
import { PositionsRequest } from '../models/positions-request';
import { ResourceWarningResponse } from '../models/resource-warning-response';

@Injectable({ providedIn: 'root' })
export class PositionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `positionsPost()` */
  static readonly PositionsPostPath = '/positions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  positionsPost$Plain$Response(
    params?: {
      body?: PositionsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PositionItemDtoResponseListDto>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.PositionsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PositionItemDtoResponseListDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `positionsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  positionsPost$Plain(
    params?: {
      body?: PositionsRequest
    },
    context?: HttpContext
  ): Observable<PositionItemDtoResponseListDto> {
    return this.positionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PositionItemDtoResponseListDto>): PositionItemDtoResponseListDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  positionsPost$Json$Response(
    params?: {
      body?: PositionsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PositionItemDtoResponseListDto>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.PositionsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PositionItemDtoResponseListDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `positionsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  positionsPost$Json(
    params?: {
      body?: PositionsRequest
    },
    context?: HttpContext
  ): Observable<PositionItemDtoResponseListDto> {
    return this.positionsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PositionItemDtoResponseListDto>): PositionItemDtoResponseListDto => r.body)
    );
  }

  /** Path part for operation `positionGet()` */
  static readonly PositionGetPath = '/position';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionGet$Plain$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PositionDto>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.PositionGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PositionDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `positionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionGet$Plain(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<PositionDto> {
    return this.positionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PositionDto>): PositionDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionGet$Json$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PositionDto>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.PositionGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PositionDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `positionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionGet$Json(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<PositionDto> {
    return this.positionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PositionDto>): PositionDto => r.body)
    );
  }

  /** Path part for operation `positionAllowedCountriesGet()` */
  static readonly PositionAllowedCountriesGetPath = '/position-allowedCountries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionAllowedCountriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionAllowedCountriesGet$Plain$Response(
    params?: {
      positionId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BaseDictionaryDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.PositionAllowedCountriesGetPath, 'get');
    if (params) {
      rb.query('positionId', params.positionId, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseDictionaryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `positionAllowedCountriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionAllowedCountriesGet$Plain(
    params?: {
      positionId?: string;
    },
    context?: HttpContext
  ): Observable<Array<BaseDictionaryDto>> {
    return this.positionAllowedCountriesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseDictionaryDto>>): Array<BaseDictionaryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionAllowedCountriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionAllowedCountriesGet$Json$Response(
    params?: {
      positionId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BaseDictionaryDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.PositionAllowedCountriesGetPath, 'get');
    if (params) {
      rb.query('positionId', params.positionId, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseDictionaryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `positionAllowedCountriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  positionAllowedCountriesGet$Json(
    params?: {
      positionId?: string;
    },
    context?: HttpContext
  ): Observable<Array<BaseDictionaryDto>> {
    return this.positionAllowedCountriesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseDictionaryDto>>): Array<BaseDictionaryDto> => r.body)
    );
  }

  /** Path part for operation `applyPositionPost()` */
  static readonly ApplyPositionPostPath = '/apply-position';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applyPositionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  applyPositionPost$Plain$Response(
    params?: {
      body?: ApplyPositionRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResourceWarningResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.ApplyPositionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceWarningResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applyPositionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  applyPositionPost$Plain(
    params?: {
      body?: ApplyPositionRequest
    },
    context?: HttpContext
  ): Observable<ResourceWarningResponse> {
    return this.applyPositionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceWarningResponse>): ResourceWarningResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applyPositionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  applyPositionPost$Json$Response(
    params?: {
      body?: ApplyPositionRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResourceWarningResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PositionsService.ApplyPositionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceWarningResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applyPositionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  applyPositionPost$Json(
    params?: {
      body?: ApplyPositionRequest
    },
    context?: HttpContext
  ): Observable<ResourceWarningResponse> {
    return this.applyPositionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceWarningResponse>): ResourceWarningResponse => r.body)
    );
  }

}
