import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { I18nService } from '@common/components/i18n';
import { DialogService } from '@common/services/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  title = 'vp';

  constructor() {
    inject(I18nService).initLang();
    inject(DialogService).init();
  }
}
