import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupedUserPreferences } from '@common/components/settings/pages/user-settings/user-settings.component';
import { UserInfo } from '@common/generated/graphql';
import { HttpStatusCode } from '@common/generated/gateway-api';

@Injectable({
  providedIn: 'root',
})
export default abstract class UserService<T> {
  abstract resetPassword(
    password: string,
    userId?: string | null,
  ): Observable<HttpStatusCode | boolean>;

  abstract getGroupedUserPreferences(
    id?: string,
  ): Observable<GroupedUserPreferences>;

  abstract updateUserPreference(
    isActive: boolean,
    preference: T,
    id?: string,
  ): Observable<GroupedUserPreferences>;

  abstract toggleNotifications(
    isActive: boolean,
    userId?: string | null,
  ): Observable<GroupedUserPreferences>;

  abstract updateUser(user: UserInfo): Observable<HttpStatusCode | string>;
}
