import { Directive } from '@angular/core';
import { StatusChipResolverDirective } from './status-chip-resolver.directive';
import { BooleanEnum } from '@common/generated/graphql';

type Item = BooleanEnum;

@Directive({
  selector: '[assetsYesNoChipResolver]',
})
export class YesNoChipResolverDirective extends StatusChipResolverDirective<Item> {
  override getValues(model: Item) {
    const classNames = {
      [BooleanEnum.No]: ['mat-chip-danger'],
      [BooleanEnum.Yes]: ['assets-chip-success'],
      [BooleanEnum.NotApplicable]: ['assets-chip-yellow'],
    }[model!] ?? [''];

    return {
      classNames,
      contentToken: model ?? '',
    };
  }
}
