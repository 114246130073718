import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiSpinnerService, SpinnerOptions } from '@common/services/api-spinner.service';
import { NotificationService } from '@common/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GraphQLError } from 'graphql/error';
import { FILTER_SPINNER_OPTION } from '@common/const/spinner.const';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly notificationService = inject(NotificationService);
  private readonly apiSpinnerService = inject(ApiSpinnerService);

  withLoader<T>(
    http$: Observable<T>,
    spinner?: SpinnerOptions,
  ): Observable<T> {
    return this.apiSpinnerService
      .loadingWrapper<T>(http$, spinner ?? {name: 'page-host', options: FILTER_SPINNER_OPTION})
      .pipe(
        catchError(error => {
          this.catchError(error);
          return EMPTY;
        }),
      );
  }

  withCatchError<T>(http$: Observable<T>): Observable<T> {
    return http$.pipe(catchError(this.catchError));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  catchError = (error: any) => {
    if (error instanceof HttpErrorResponse) {
      const hasErrorsExtensions = (error.error as unknown as GraphQLError)
        .extensions;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any

      if (hasErrorsExtensions) {
        return throwError(() => error);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (error.error as any).errors;
      if (errors) {
        this.notificationService.notifyError(errors[0].message);
      }
    }
    return throwError(() => error);
  };
}
