import { inject } from '@angular/core';
import { AuthService } from '@common/generated/gateway-api';
import { RefreshTokenService } from '@common/services/refresh-token.service';
import { map } from 'rxjs';

export class RefreshTokenVPService implements RefreshTokenService {
  authService = inject(AuthService);

  refreshToken(token: string) {
    return this.authService
      .refreshPost$Json({
        body: {
          token,
        },
      })
      .pipe(
        map(data => {
          const { accessToken, refreshToken } = data;
          return {
            accessToken: accessToken ?? '',
            refreshToken: refreshToken ?? '',
          };
        }),
      );
  }
}
