import { Routes } from '@angular/router';
import ContentLayoutComponent from '@common/components/wrapper/layout/content-layout/content-layout.component';
import { authGuard, publicGuard } from '@common/guards';
import AuthLayoutComponent from '@common/components/wrapper/layout/auth-layout/auth-layout.component';
import { ROUTES, SETTINGS } from '@common/const';
import {
  ALLOCATED_RESOURCES,
  CANDIDATES_STATUS,
  OPEN_POSITIONS,
  TIME_SUBMISSION,
  RESOURCES,
  INVOICES,
  COMMISSION_FEE,
} from './shared/const';
import { NOT_FOUND_ROUTE } from '@common/shared.routing';

export const appRoutes: Routes = [
  {
    path: ROUTES.auth,
    component: AuthLayoutComponent,
    canMatch: [publicGuard],
    children: [
      {
        path: '',
        data: {
          isGoogleSignInEnabled: false,
        },
        loadChildren: () => import('@common/modules/auth/auth.module'),
      },
    ],
  },
  {
    path: ROUTES.root,
    component: ContentLayoutComponent,
    data: { breadcrumb: 'Home' },
    // canMatch: [() => checkIfAuthorizedGuard(!!inject(AuthenticationService).currentUser)],
    canActivate: [authGuard],
    children: [
      NOT_FOUND_ROUTE,
      {
        path: ROUTES.root,
        pathMatch: 'full',
        redirectTo: OPEN_POSITIONS.root,
      },
      {
        path: OPEN_POSITIONS.root,
        loadChildren: () =>
          import('./modules/open-positions/open-positions.routes'),
        data: {
          breadcrumb: 'OPEN_POSITIONS',
        },
      },
      {
        path: CANDIDATES_STATUS.root,
        loadChildren: () =>
          import('./modules/candidates-status/candidates-status.routes'),
        data: {
          breadcrumb: 'CANDIDATES_STATUS',
        },
      },
      {
        path: RESOURCES.root,
        loadChildren: () => import('./modules/resources/resources.routes'),
        data: {
          breadcrumb: 'RESOURCES',
        },
      },
      {
        path: ALLOCATED_RESOURCES.root,
        loadChildren: () =>
          import('./modules/allocated-resources/allocated-resources.routes'),
        data: {
          breadcrumb: 'ALLOCATED_RESOURCES',
        },
      },
      {
        path: TIME_SUBMISSION.root,
        loadChildren: () =>
          import('./modules/report-hours/report-hours.routes'),
        data: {
          breadcrumb: 'TIME_SUBMISSION',
        },
      },
      {
        path: INVOICES.root,
        loadChildren: () => import('./modules/invoices/invoices.routes'),
        data: {
          breadcrumb: 'INVOICES',
        },
      },
      {
        path: SETTINGS.root,
        loadChildren: () =>
          import('./modules/settings/vp-user-settings.routes'),
      },
      {
        path: COMMISSION_FEE.root,
        loadChildren: () =>
          import('./modules/commission-fees/commission-fee.routes'),
        data: {
          breadcrumb: 'COMMISSION_FEE',
        },
      },
    ],
  },
];
