import { inject, Injectable } from '@angular/core';
import ReportIssueService from '@common/services/report-issue.service';
import { UserApiService } from './user-api.service';

@Injectable({
  providedIn: 'root',
})
export default class ReportIssueVpService extends ReportIssueService {
  userApiService = inject(UserApiService);

  override reportIssue(message: string) {
    return this.userApiService.reportIssueVp(message);
  }
}
