<div class="assets-form assets-form-field" *ngIf="control && innerControl">
  <assets-form-upload
    [required]="required"
    [control]="innerControl"
    [accept]="accept"
    [label]="label"
    [clearHidden]="clearHidden"
    (file)="onFileChange($event)"
  >
    <ng-template #name *ngIf="control.controls!.id!.value">
      {{ control.controls.name.value }}
    </ng-template>
  </assets-form-upload>
  <mat-error *ngIf="control?.invalid">{{ getErrorMessage() }}</mat-error>
</div>
