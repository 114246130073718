import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@root/environments/environment';

export default class CredentialsInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (new URL(request.url, environment.apiURL).pathname === '/access') {
      request = request.clone({ withCredentials: true });
    }

    return next.handle(request);
  }
}
