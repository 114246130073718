/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddCommentRequest } from '../models/add-comment-request';
import { BaseDictionaryDto } from '../models/base-dictionary-dto';
import { DictionariesEnum } from '../models/dictionaries-enum';
import { ReleaseVersionResponse } from '../models/release-version-response';

@Injectable({ providedIn: 'root' })
export class CommonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `versionGet()` */
  static readonly VersionGetPath = '/version';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReleaseVersionResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CommonService.VersionGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReleaseVersionResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<ReleaseVersionResponse> {
    return this.versionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReleaseVersionResponse>): ReleaseVersionResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReleaseVersionResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CommonService.VersionGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReleaseVersionResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<ReleaseVersionResponse> {
    return this.versionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReleaseVersionResponse>): ReleaseVersionResponse => r.body)
    );
  }

  /** Path part for operation `dictionariesGet()` */
  static readonly DictionariesGetPath = '/dictionaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionariesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionariesGet$Plain$Response(
    params?: {
      dictionaryType?: DictionariesEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BaseDictionaryDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CommonService.DictionariesGetPath, 'get');
    if (params) {
      rb.query('dictionaryType', params.dictionaryType, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseDictionaryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionariesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionariesGet$Plain(
    params?: {
      dictionaryType?: DictionariesEnum;
    },
    context?: HttpContext
  ): Observable<Array<BaseDictionaryDto>> {
    return this.dictionariesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseDictionaryDto>>): Array<BaseDictionaryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionariesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionariesGet$Json$Response(
    params?: {
      dictionaryType?: DictionariesEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<BaseDictionaryDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CommonService.DictionariesGetPath, 'get');
    if (params) {
      rb.query('dictionaryType', params.dictionaryType, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseDictionaryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionariesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionariesGet$Json(
    params?: {
      dictionaryType?: DictionariesEnum;
    },
    context?: HttpContext
  ): Observable<Array<BaseDictionaryDto>> {
    return this.dictionariesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BaseDictionaryDto>>): Array<BaseDictionaryDto> => r.body)
    );
  }

  /** Path part for operation `reportIssuePost()` */
  static readonly ReportIssuePostPath = '/report-issue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportIssuePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportIssuePost$Response(
    params?: {
      body?: AddCommentRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CommonService.ReportIssuePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportIssuePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportIssuePost(
    params?: {
      body?: AddCommentRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.reportIssuePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
