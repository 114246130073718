import {
  AbstractControl,
  AbstractControlOptions,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ɵElement,
} from '@angular/forms';

export const hasRequiredField = (ctrl: AbstractControl): boolean => {
  return ctrl.hasValidator(Validators.required);
};

type ControlsConfig<K = string[][number]> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in keyof K]: any;
};

export const getFieldsFromControlsConfig = <K>(
  controlsConfig: ControlsConfig<K>,
  fields?: (keyof ControlsConfig<K>)[],
): {
  [Key in NonNullable<typeof fields>[number]]: ControlsConfig<K>[Key];
} => {
  if (!fields || !fields.length) {
    return controlsConfig;
  }

  return fields.reduce(
    (acc, field) => ({ ...acc, [field]: controlsConfig[field] }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as any,
  );
};

export function formArrayFactory<T extends unknown | null>(
  model: T[] | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getControls: (item?: T) => { [K in keyof T]?: any },
  options?: {
    minRequiredGroups?: number;
    groupOptions?: AbstractControlOptions;
  },
) {
  const fb = new FormBuilder();
  model = model ?? [];

  type FGroup = FormGroup<{
    [K in keyof T]: ɵElement<T[K], never>;
  }>;

  const getGroups = (item?: T) =>
    fb.nonNullable.group(getControls(item), options?.groupOptions) as FGroup;

  let groups: FGroup[] = [];
  let groupsToBeAddedCount = options?.minRequiredGroups ?? 0;

  if (model.length) {
    groups = model.map(item => getGroups(item));
    groupsToBeAddedCount = groupsToBeAddedCount - model.length;
  }

  for (let i = 0; i < groupsToBeAddedCount; i++) {
    groups.push(getGroups());
  }

  return new FormArray(
    groups,
    options?.minRequiredGroups ? Validators.required : null,
  );
}

export const monthAndYear = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
};

export const year = new Date().getFullYear();

export const updateFormValidity = (form: FormGroup) => {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control instanceof FormGroup) {
      updateFormValidity(control);
    } else {
      control?.updateValueAndValidity();
    }
  });
};
