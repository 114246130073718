/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ForgotPasswordRequest } from '../models/forgot-password-request';
import { GroupedUserPreferencesDto } from '../models/grouped-user-preferences-dto';
import { HttpStatusCode } from '../models/http-status-code';
import { ResetPasswordRequest } from '../models/reset-password-request';
import { ToggleNotificationsRequest } from '../models/toggle-notifications-request';
import { UpdateUserPreferenceRequest } from '../models/update-user-preference-request';
import { UserUpdateRequest } from '../models/user-update-request';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `forgotPasswordPost()` */
  static readonly ForgotPasswordPostPath = '/forgot-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  forgotPasswordPost$Plain$Response(
    params?: {
      body?: ForgotPasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forgotPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  forgotPasswordPost$Plain(
    params?: {
      body?: ForgotPasswordRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.forgotPasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  forgotPasswordPost$Json$Response(
    params?: {
      body?: ForgotPasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `forgotPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  forgotPasswordPost$Json(
    params?: {
      body?: ForgotPasswordRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.forgotPasswordPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `resetPasswordPost()` */
  static readonly ResetPasswordPostPath = '/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetPasswordPost$Plain$Response(
    params?: {
      body?: ResetPasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HttpStatusCode>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HttpStatusCode>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetPasswordPost$Plain(
    params?: {
      body?: ResetPasswordRequest
    },
    context?: HttpContext
  ): Observable<HttpStatusCode> {
    return this.resetPasswordPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HttpStatusCode>): HttpStatusCode => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetPasswordPost$Json$Response(
    params?: {
      body?: ResetPasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HttpStatusCode>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HttpStatusCode>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  resetPasswordPost$Json(
    params?: {
      body?: ResetPasswordRequest
    },
    context?: HttpContext
  ): Observable<HttpStatusCode> {
    return this.resetPasswordPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HttpStatusCode>): HttpStatusCode => r.body)
    );
  }

  /** Path part for operation `updateUserPost()` */
  static readonly UpdateUserPostPath = '/update-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPost$Plain$Response(
    params?: {
      body?: UserUpdateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HttpStatusCode>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HttpStatusCode>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPost$Plain(
    params?: {
      body?: UserUpdateRequest
    },
    context?: HttpContext
  ): Observable<HttpStatusCode> {
    return this.updateUserPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HttpStatusCode>): HttpStatusCode => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPost$Json$Response(
    params?: {
      body?: UserUpdateRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HttpStatusCode>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HttpStatusCode>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPost$Json(
    params?: {
      body?: UserUpdateRequest
    },
    context?: HttpContext
  ): Observable<HttpStatusCode> {
    return this.updateUserPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HttpStatusCode>): HttpStatusCode => r.body)
    );
  }

  /** Path part for operation `groupedUserPreferencesGet()` */
  static readonly GroupedUserPreferencesGetPath = '/grouped-user-preferences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupedUserPreferencesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupedUserPreferencesGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupedUserPreferencesDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GroupedUserPreferencesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupedUserPreferencesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupedUserPreferencesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupedUserPreferencesGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<GroupedUserPreferencesDto> {
    return this.groupedUserPreferencesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedUserPreferencesDto>): GroupedUserPreferencesDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupedUserPreferencesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupedUserPreferencesGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupedUserPreferencesDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.GroupedUserPreferencesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupedUserPreferencesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupedUserPreferencesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groupedUserPreferencesGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<GroupedUserPreferencesDto> {
    return this.groupedUserPreferencesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedUserPreferencesDto>): GroupedUserPreferencesDto => r.body)
    );
  }

  /** Path part for operation `updateUserPreferencePost()` */
  static readonly UpdateUserPreferencePostPath = '/update-user-preference';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPreferencePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPreferencePost$Plain$Response(
    params?: {
      body?: UpdateUserPreferenceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupedUserPreferencesDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPreferencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupedUserPreferencesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserPreferencePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPreferencePost$Plain(
    params?: {
      body?: UpdateUserPreferenceRequest
    },
    context?: HttpContext
  ): Observable<GroupedUserPreferencesDto> {
    return this.updateUserPreferencePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedUserPreferencesDto>): GroupedUserPreferencesDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPreferencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPreferencePost$Json$Response(
    params?: {
      body?: UpdateUserPreferenceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupedUserPreferencesDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPreferencePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupedUserPreferencesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserPreferencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUserPreferencePost$Json(
    params?: {
      body?: UpdateUserPreferenceRequest
    },
    context?: HttpContext
  ): Observable<GroupedUserPreferencesDto> {
    return this.updateUserPreferencePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedUserPreferencesDto>): GroupedUserPreferencesDto => r.body)
    );
  }

  /** Path part for operation `toggleNotificationsPost()` */
  static readonly ToggleNotificationsPostPath = '/toggle-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleNotificationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggleNotificationsPost$Plain$Response(
    params?: {
      body?: ToggleNotificationsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupedUserPreferencesDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ToggleNotificationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupedUserPreferencesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleNotificationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggleNotificationsPost$Plain(
    params?: {
      body?: ToggleNotificationsRequest
    },
    context?: HttpContext
  ): Observable<GroupedUserPreferencesDto> {
    return this.toggleNotificationsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedUserPreferencesDto>): GroupedUserPreferencesDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleNotificationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggleNotificationsPost$Json$Response(
    params?: {
      body?: ToggleNotificationsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupedUserPreferencesDto>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ToggleNotificationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupedUserPreferencesDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleNotificationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  toggleNotificationsPost$Json(
    params?: {
      body?: ToggleNotificationsRequest
    },
    context?: HttpContext
  ): Observable<GroupedUserPreferencesDto> {
    return this.toggleNotificationsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedUserPreferencesDto>): GroupedUserPreferencesDto => r.body)
    );
  }

}
