import { Injectable, inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '@common/services/authentication.service';
import {
  AuthService,
  SignInResponse,
  UsersService,
} from '@common/generated/gateway-api';
import { of } from 'rxjs';
import { SignInQueryVariables } from '@common/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationVPService extends AuthenticationService {
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UsersService);

  override login(params: SignInQueryVariables) {
    const auth = params.SignInRequestInput!.auth;
    return this.authService
      .accessPost$Json({
        body: {
          login: auth?.login,
          password: auth?.password,
          code: auth?.code,
          trustDevice: auth?.trustDevice,
        },
      })
      .pipe(
        map((response: SignInResponse) => this.mapSignInResponse(response)),
        catchError(response => {
          return of(this.mapSignInResponse(response, response.error?.errors));
        }),
      );
  }

  override resend2FACode(email: string) {
    return this.authService.resendTwoStepCodePost({
      body: {
        email,
      },
    });
  }

  override forgotPassword(email: string) {
    return this.userService.forgotPasswordPost$Json({ body: { email } });
  }
}
